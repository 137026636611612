import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _41c45f1c = () => interopDefault(import('../pages/wrapper.vue' /* webpackChunkName: "wrapper" */))
const _cd863b84 = () => interopDefault(import('../pages/careers.vue' /* webpackChunkName: "careers" */))
const _58d4bb80 = () => interopDefault(import('../pages/teltonika-iot-group.com-landing.vue' /* webpackChunkName: "teltonika-iot-group.com-landing" */))
const _73ff5b1d = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "news" */))
const _ea1bd202 = () => interopDefault(import('../pages/defaultContent.vue' /* webpackChunkName: "null" */))
const _083b430f = () => interopDefault(import('../pages/rma.vue' /* webpackChunkName: "rma" */))
const _09140c26 = () => interopDefault(import('../pages/rma-engineer-area.vue' /* webpackChunkName: "rma-engineer-area" */))
const _2673e8af = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "search" */))
const _928d5fe4 = () => interopDefault(import('../pages/dynamic-newsletter.vue' /* webpackChunkName: "dynamic-newsletter" */))
const _10084dc2 = () => interopDefault(import('../pages/new-marketing-material.vue' /* webpackChunkName: "new-marketing-material" */))
const _43c793cc = () => interopDefault(import('../pages/contacts.vue' /* webpackChunkName: "contacts" */))
const _263f6068 = () => interopDefault(import('../pages/leadership.vue' /* webpackChunkName: "leadership" */))
const _17157922 = () => interopDefault(import('../pages/mission-vision-values.vue' /* webpackChunkName: "mission-vision-values" */))
const _89ee1394 = () => interopDefault(import('../pages/policiesCertificates/index.vue' /* webpackChunkName: "policiesCertificates" */))
const _8d5b5624 = () => interopDefault(import('../pages/policiesCertificates/_slug.vue' /* webpackChunkName: "policiesCertificates" */))
const _7248b9d5 = () => interopDefault(import('../pages/news/_slug.vue' /* webpackChunkName: "news" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about-us",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fabout-us","lt":"\u002Fapie-mus"},"page_id":14},
    name: "about-us-14___en"
  }, {
    path: "/career",
    component: _cd863b84,
    meta: {"paths":{"en":"\u002Fcareer","lt":"\u002Fkarjera"},"page_id":21},
    name: "career-21___en"
  }, {
    path: "/lt",
    component: _58d4bb80,
    meta: {"paths":{"en":"\u002F","lt":"\u002F"},"page_id":1},
    name: "teltonika-iot-group.com-landing-1___lt"
  }, {
    path: "/news",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fnews","lt":"\u002Fnaujienos-1"},"page_id":7},
    name: "news-7___en"
  }, {
    path: "/newsroom",
    component: _73ff5b1d,
    meta: {"paths":{"en":"\u002Fnewsroom","lt":"\u002Fnaujienos"},"page_id":8},
    name: "newsroom-8___en"
  }, {
    path: "/racing-media-archive",
    component: _ea1bd202,
    meta: {"paths":{"en":"\u002Fracing-media-archive","lt":"\u002Fracing-media-archive"},"page_id":25},
    name: "racing-media-archive-25___en"
  }, {
    path: "/rma",
    component: _083b430f,
    meta: {"paths":{"en":"\u002Frma","lt":"\u002Frma"},"page_id":42},
    name: "rma-42___en"
  }, {
    path: "/rma-engineer-area",
    component: _09140c26,
    meta: {"paths":{"en":"\u002Frma-engineer-area","lt":"\u002Frma-engineer-area"},"page_id":43},
    name: "rma-engineer-area-43___en"
  }, {
    path: "/search",
    component: _2673e8af,
    meta: {"paths":{"en":"\u002Fsearch","lt":"\u002Fpaieska"},"page_id":45},
    name: "search-45___en"
  }, {
    path: "/subscribe",
    component: _928d5fe4,
    meta: {"paths":{"en":"\u002Fsubscribe","lt":"\u002Fprenumeruok"},"page_id":65},
    name: "subscribe-65___en"
  }, {
    path: "/subscribe-old",
    component: _ea1bd202,
    meta: {"paths":{"en":"\u002Fsubscribe-old","lt":"\u002Fprenumeruoti-old"},"page_id":13},
    name: "subscribe-old-13___en"
  }, {
    path: "/teltonika-racing",
    component: _ea1bd202,
    meta: {"paths":{"en":"\u002Fteltonika-racing","lt":"\u002Fteltonika-racing"},"page_id":49},
    name: "teltonika-racing-49___en"
  }, {
    path: "/translations",
    component: _ea1bd202,
    meta: {"paths":{"en":"\u002Ftranslations"}},
    name: "defaultContent___en"
  }, {
    path: "/about-us/brand-guidelines",
    component: _10084dc2,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fbrand-guidelines","lt":"\u002Fapie-mus\u002Fbrand-guidelines"},"page_id":123},
    name: "about-us-brand-guidelines-123___en"
  }, {
    path: "/about-us/contacts",
    component: _43c793cc,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fcontacts","lt":"\u002Fapie-mus\u002Fkontaktai"},"page_id":19},
    name: "about-us-contacts-19___en"
  }, {
    path: "/about-us/management-team",
    component: _263f6068,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fmanagement-team","lt":"\u002Fapie-mus\u002Fvadovu-komanda"},"page_id":17},
    name: "about-us-management-team-17___en"
  }, {
    path: "/about-us/mission-vision-values",
    component: _17157922,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fmission-vision-values","lt":"\u002Fapie-mus\u002Fmisija-vizija-ir-vertybes"},"page_id":16},
    name: "about-us-mission-vision-values-16___en"
  }, {
    path: "/about-us/policies-certificates",
    component: _89ee1394,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fpolicies-certificates","lt":"\u002Fapie-mus\u002Fpolitika-ir-sertifikatai"},"page_id":18},
    name: "about-us-policies-certificates-18___en"
  }, {
    path: "/lt/apie-mus",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fabout-us","lt":"\u002Fapie-mus"},"page_id":14},
    name: "about-us-14___lt"
  }, {
    path: "/lt/karjera",
    component: _cd863b84,
    meta: {"paths":{"en":"\u002Fcareer","lt":"\u002Fkarjera"},"page_id":21},
    name: "career-21___lt"
  }, {
    path: "/lt/naujienos-1",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fnews","lt":"\u002Fnaujienos-1"},"page_id":7},
    name: "news-7___lt"
  }, {
    path: "/lt/naujienos",
    component: _73ff5b1d,
    meta: {"paths":{"en":"\u002Fnewsroom","lt":"\u002Fnaujienos"},"page_id":8},
    name: "newsroom-8___lt"
  }, {
    path: "/lt/racing-media-archive",
    component: _ea1bd202,
    meta: {"paths":{"en":"\u002Fracing-media-archive","lt":"\u002Fracing-media-archive"},"page_id":25},
    name: "racing-media-archive-25___lt"
  }, {
    path: "/lt/rma",
    component: _083b430f,
    meta: {"paths":{"en":"\u002Frma","lt":"\u002Frma"},"page_id":42},
    name: "rma-42___lt"
  }, {
    path: "/lt/rma-engineer-area",
    component: _09140c26,
    meta: {"paths":{"en":"\u002Frma-engineer-area","lt":"\u002Frma-engineer-area"},"page_id":43},
    name: "rma-engineer-area-43___lt"
  }, {
    path: "/lt/paieska",
    component: _2673e8af,
    meta: {"paths":{"en":"\u002Fsearch","lt":"\u002Fpaieska"},"page_id":45},
    name: "search-45___lt"
  }, {
    path: "/lt/prenumeruok",
    component: _928d5fe4,
    meta: {"paths":{"en":"\u002Fsubscribe","lt":"\u002Fprenumeruok"},"page_id":65},
    name: "subscribe-65___lt"
  }, {
    path: "/lt/prenumeruoti-old",
    component: _ea1bd202,
    meta: {"paths":{"en":"\u002Fsubscribe-old","lt":"\u002Fprenumeruoti-old"},"page_id":13},
    name: "subscribe-old-13___lt"
  }, {
    path: "/lt/teltonika-racing",
    component: _ea1bd202,
    meta: {"paths":{"en":"\u002Fteltonika-racing","lt":"\u002Fteltonika-racing"},"page_id":49},
    name: "teltonika-racing-49___lt"
  }, {
    path: "/lt/translations",
    component: _ea1bd202,
    meta: {"paths":{"en":"\u002Ftranslations"}},
    name: "defaultContent___lt"
  }, {
    path: "/lt/apie-mus/brand-guidelines",
    component: _10084dc2,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fbrand-guidelines","lt":"\u002Fapie-mus\u002Fbrand-guidelines"},"page_id":123},
    name: "about-us-brand-guidelines-123___lt"
  }, {
    path: "/lt/apie-mus/kontaktai",
    component: _43c793cc,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fcontacts","lt":"\u002Fapie-mus\u002Fkontaktai"},"page_id":19},
    name: "about-us-contacts-19___lt"
  }, {
    path: "/lt/apie-mus/vadovu-komanda",
    component: _263f6068,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fmanagement-team","lt":"\u002Fapie-mus\u002Fvadovu-komanda"},"page_id":17},
    name: "about-us-management-team-17___lt"
  }, {
    path: "/lt/apie-mus/misija-vizija-ir-vertybes",
    component: _17157922,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fmission-vision-values","lt":"\u002Fapie-mus\u002Fmisija-vizija-ir-vertybes"},"page_id":16},
    name: "about-us-mission-vision-values-16___lt"
  }, {
    path: "/lt/apie-mus/politika-ir-sertifikatai",
    component: _89ee1394,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fpolicies-certificates","lt":"\u002Fapie-mus\u002Fpolitika-ir-sertifikatai"},"page_id":18},
    name: "about-us-policies-certificates-18___lt"
  }, {
    path: "/",
    component: _58d4bb80,
    meta: {"paths":{"en":"\u002F","lt":"\u002F"},"page_id":1},
    name: "teltonika-iot-group.com-landing-1___en"
  }, {
    path: "/lt/apie-mus/politika-ir-sertifikatai/:slug",
    component: _8d5b5624,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fpolicies-certificates\u002F:slug","lt":"\u002Fapie-mus\u002Fpolitika-ir-sertifikatai\u002F:slug"},"page_id":18},
    name: "about-us-policies-certificates-:slug-18-slug___lt"
  }, {
    path: "/about-us/policies-certificates/:slug",
    component: _8d5b5624,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fpolicies-certificates\u002F:slug","lt":"\u002Fapie-mus\u002Fpolitika-ir-sertifikatai\u002F:slug"},"page_id":18},
    name: "about-us-policies-certificates-:slug-18-slug___en"
  }, {
    path: "/lt/naujienos/:slug",
    component: _7248b9d5,
    meta: {"paths":{"en":"\u002Fnewsroom\u002F:slug","lt":"\u002Fnaujienos\u002F:slug"},"page_id":8},
    name: "newsroom-:slug-8-slug___lt"
  }, {
    path: "/newsroom/:slug",
    component: _7248b9d5,
    meta: {"paths":{"en":"\u002Fnewsroom\u002F:slug","lt":"\u002Fnaujienos\u002F:slug"},"page_id":8},
    name: "newsroom-:slug-8-slug___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
